/**
 * Set Custom Fonts family and standard font sizes
 * should be written here.
 */

@font-face {
  font-family: 'fontRegular';
  src: url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'fontRegularItalic';
  src: url(./fonts/fontRegularItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'fontMedium';
  src: url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'fontSemibold';
  src: url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'fontLight';
  src: url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'fontThin';
  src: url(./fonts/fontThin.ttf) format('truetype');
}

@font-face {
  font-family: 'fontBold';
  src: url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'fontHeavy';
  src: url(./fonts/fontHeavy.ttf) format('truetype');
}
input {
  width: 100%;
}
input:focus-visible {
  outline: 0;
}
